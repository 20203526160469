<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h3>
            Company Information
            <v-progress-circular indeterminate color="primary" />
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <v-row>
            <!-- Side Navigation -->
            <v-col cols="2">
              <div class="side-nav">
                SECTIONS:
                <v-list dense>
                  <!-- Navigation Links -->
                  <v-list-item
                    @click="
                      scrollToElement($refs.company_info.$el);
                      if (!panels.includes(0)) panels.push(0);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        Company Information
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="companyData.lender"
                    @click="
                      scrollToElement($refs.form_response.$el);
                      if (!panels.includes(1)) panels.push(1);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Form Response </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      scrollToElement($refs.accounts.$el);
                      if (companyData.lender && !panels.includes(2))
                        panels.push(2);
                      if (!companyData.lender && !panels.includes(1))
                        panels.push(1);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Accounts </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      scrollToElement($refs.associated_users.$el);
                      if (companyData.lender && !panels.includes(3))
                        panels.push(3);
                      if (!companyData.lender && !panels.includes(2))
                        panels.push(2);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Associated Users </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    @click="
                      scrollToElement($refs.company_note.$el);
                      if (companyData.lender && !panels.includes(4))
                        panels.push(4);
                      if (!companyData.lender && !panels.includes(3))
                        panels.push(3);
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title> Notes </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="panels = [0, 1, 2, 3]">
                    <v-list-item-content>
                      <v-list-item-title class="green--text">
                        Expand All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="panels = []">
                    <v-list-item-content>
                      <v-list-item-title class="red--text">
                        Collapse All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>

            <v-col cols="10">
              <v-expansion-panels v-model="panels" multiple>
                <v-expansion-panel
                  class="bordered"
                  style="background-color: transparent"
                  ref="company_info"
                >
                  <v-expansion-panel-header>
                    <h3>
                      Company Information
                      <router-link
                        style="font-size: 14px"
                        :to="{
                          path: `/dashboard/admin/company/edit/${companyData.id}`,
                        }"
                      >
                        (Edit)
                      </router-link>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-simple-table dense>
                      <thead>
                        <th :width="450"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company Name</td>
                          <td>{{ companyData.name }}</td>
                        </tr>
                        <tr>
                          <td>Website</td>
                          <td>{{ companyData.website }}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td>{{ companyData.phone }}</td>
                        </tr>

                        <tr>
                          <td>Present Address</td>
                          <td
                            v-if="
                              !companyData.addresses.some(
                                (address) => address.present
                              )
                            "
                          >
                            No address found
                          </td>
                          <td
                            v-if="
                              companyData.addresses.some(
                                (address) => address.present
                              )
                            "
                          >
                            {{ present_address.street_number }}
                            {{ present_address.street_name }}
                            {{ present_address.street_type }}
                            {{ present_address.street_direction }}
                            {{ present_address.unit }}
                            {{ present_address.postal_code }}
                            {{ present_address.city }}
                            {{ present_address.province }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <br />

                    <v-simple-table dense>
                      <thead>
                        <th :width="450"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lender:</td>
                          <td>{{ companyData.lender | yes_no }}</td>
                        </tr>
                        <tr>
                          <td>Appraiser:</td>
                          <td>{{ companyData.appraiser | yes_no }}</td>
                        </tr>
                        <tr>
                          <td>Lawyer:</td>
                          <td>{{ companyData.lawyer | yes_no }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <br />

                    <v-simple-table dense>
                      <thead>
                        <th :width="450"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Tag</td>
                          <td class="text-capitalize">
                            {{
                              companyData.lending_profile
                                ? companyData.lending_profile.tier
                                : "None"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Company Active</td>
                          <td>
                            {{ companyData.active | booleanString }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <br />

                    <v-simple-table dense>
                      <thead>
                        <th :width="450"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>BC</td>
                          <td>
                            {{
                              companyData.lending_profile
                                ? companyData.lending_profile.BC
                                : false | booleanString
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Alberta</td>
                          <td>
                            {{
                              companyData.lending_profile
                                ? companyData.lending_profile.AB
                                : false | booleanString
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Ontario</td>
                          <td>
                            {{
                              companyData.lending_profile
                                ? companyData.lending_profile.ON
                                : false | booleanString
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <br />

                    <v-simple-table dense>
                      <thead>
                        <th :width="450"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Conflict of Interest</td>
                          <td>
                            {{
                              companyData.conflict_of_interest | booleanString
                            }}
                          </td>
                        </tr>
                        <tr v-if="companyData.conflict_of_interest">
                          <td>Direct Conflict Interest</td>
                          <td>
                            {{ companyData.direct_conflict_of_interest }}
                          </td>
                        </tr>
                        <tr v-if="companyData.conflict_of_interest">
                          <td>Indirect Conflict Interest</td>
                          <td>
                            {{ companyData.indirect_conflict_of_interest }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <br />

                    <v-simple-table dense>
                      <thead>
                        <th :width="450"></th>
                        <th></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lender Stage</td>
                          <td v-if="companyData.kbn_stg !== null">
                            {{ companyData.kbn_stg.stage_name }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>

                    <v-btn
                      v-if="companyData.lender"
                      class="mt-5"
                      @click="handle_create_investor_kyc"
                    >
                      Create Investor KYC
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel
                  v-if="companyData.lender"
                  class="bordered"
                  style="background-color: transparent"
                  ref="form_response"
                >
                  <v-expansion-panel-header>
                    <h3>Form Responses</h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-data-table
                      :headers="form_response_headers"
                      :items="companyData.frm_rsps"
                      :items-per-page="5"
                      @click:row="view_form_response"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.id`]="{ item }">
                        {{ item.frm !== null ? item.frm.unique_id : "" }}
                      </template>

                      <template v-slot:[`item.title`]="{ item }">
                        {{ item.frm !== null ? item.frm.title : "" }}
                      </template>

                      <template v-slot:[`item.createdAt`]="{ item }">
                        {{ item.createdAt | dateFormat }}
                      </template>
                    </v-data-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel class="bordered" ref="accounts">
                  <v-expansion-panel-header>
                    <h3>Accounts</h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <v-data-table
                          :headers="account_headers"
                          :items="companyData.accounts"
                          :items-per-page="5"
                          item-key="id"
                          @click:row="handle_update_account"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-btn
                          @click="
                            $router.push({
                              path: `/dashboard/admin/company/account/add/${company_id}`,
                            })
                          "
                        >
                          Create Account
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row v-if="archived_accounts.length > 0">
                      <v-col>
                        <v-expansion-panels>
                          <v-expansion-panel class="bordered">
                            <v-expansion-panel-header>
                              Archived Accounts
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                              <v-data-table
                                :headers="account_headers"
                                :items="archived_accounts"
                                :items-per-page="5"
                                item-key="id"
                                @click:row="handle_unarchive_account"
                              />
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel
                  class="bordered"
                  style="background-color: transparent"
                  ref="associated_users"
                >
                  <v-expansion-panel-header>
                    <h3>Associated Users</h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <p class="grey--text">
                      Below are the list of users associated with this company.
                      You can add new users or associate existing users to this
                      company. To add a new user, click on the
                      <b> ADD NEW USER </b> button and if you want to add an
                      existing user, click on the
                      <b> ADD EXISTING USER </b> button.
                    </p>

                    <v-expansion-panels multiple>
                      <v-expansion-panel class="bordered">
                        <v-expansion-panel-header>
                          <h3>
                            Company Users
                            <v-btn
                              class="ml-4"
                              title="Edit Associated Users"
                              icon
                              @click="
                                $router.push({
                                  path: `/dashboard/admin/company/associate_user/`,
                                  query: {
                                    company_id: `${company_id}`,
                                    view_type: 'company',
                                  },
                                })
                              "
                            >
                              <v-icon> mdi-account-edit-outline </v-icon>
                            </v-btn>
                          </h3>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <p class="grey--text">
                            These are the users that can make an offer, decline
                            an offer and monitor the bids on behalf of this
                            company.
                          </p>

                          <div v-for="user in companyData.users" :key="user.id">
                            <v-simple-table dense>
                              <thead>
                                <th :width="450"></th>
                                <th></th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>User Name</td>
                                  <td>
                                    {{ user.first_name }} {{ user.last_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>{{ user.email }}</td>
                                </tr>
                                <tr>
                                  <td>Cellphone Number</td>
                                  <td>
                                    <div
                                      v-for="contact_info in user.contact_infos.filter(
                                        (el) => el.type === 'Cell Phone Number'
                                      )"
                                      :key="contact_info.id"
                                    >
                                      {{ contact_info.number }}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Home Phone Number</td>
                                  <td>
                                    <div
                                      v-for="contact_info in user.contact_infos.filter(
                                        (el) => el.type === 'Home Phone Number'
                                      )"
                                      :key="contact_info.id"
                                    >
                                      {{ contact_info.number }}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Work Phone Number</td>
                                  <td>
                                    <div
                                      v-for="contact_info in user.contact_infos.filter(
                                        (el) => el.type === 'Work Phone Number'
                                      )"
                                      :key="contact_info.id"
                                    >
                                      {{ contact_info.number }}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Send Emails on Opportunities</td>
                                  <td>
                                    {{ user.send_summary_offer_email | yes_no }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Blocked Status</td>
                                  <td>{{ user.blocked | yes_no }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>

                            <br />
                          </div>

                          <v-btn
                            @click="
                              $router.push({
                                path: '/dashboard/admin/user/add',
                              })
                            "
                          >
                            Add New User
                          </v-btn>

                          &nbsp;

                          <v-btn
                            @click="
                              $router.push({
                                path: `/dashboard/admin/company/associate_user/`,
                                query: {
                                  company_id: `${company_id}`,
                                  view_type: 'company',
                                },
                              })
                            "
                          >
                            Add Existing User
                          </v-btn>
                        </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel class="bordered">
                        <v-expansion-panel-header>
                          <h3>
                            Authorized Users
                            <v-btn
                              class="ml-4"
                              title="Edit Associated Users"
                              icon
                              @click="
                                $router.push({
                                  path: `/dashboard/admin/company/associate_user/`,
                                  query: {
                                    company_id: `${company_id}`,
                                    view_type: 'authorized',
                                  },
                                })
                              "
                            >
                              <v-icon> mdi-account-edit-outline </v-icon>
                            </v-btn>
                          </h3>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <p class="grey--text">
                            These are authorized users that can make an offer on
                            behalf of this company.
                          </p>

                          <div
                            v-for="user in companyData.auth_users"
                            :key="user.id"
                          >
                            <v-simple-table dense>
                              <thead>
                                <th :width="450"></th>
                                <th></th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>User Name</td>
                                  <td>
                                    {{ user.first_name }} {{ user.last_name }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>{{ user.email }}</td>
                                </tr>
                                <tr>
                                  <td>Cellphone Number</td>
                                  <td>
                                    <div
                                      v-for="contact_info in user.contact_infos.filter(
                                        (el) => el.type === 'Cell Phone Number'
                                      )"
                                      :key="contact_info.id"
                                    >
                                      {{ contact_info.number }}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Home Phone Number</td>
                                  <td>
                                    <div
                                      v-for="contact_info in user.contact_infos.filter(
                                        (el) => el.type === 'Home Phone Number'
                                      )"
                                      :key="contact_info.id"
                                    >
                                      {{ contact_info.number }}
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>Work Phone Number</td>
                                  <td>
                                    <div
                                      v-for="contact_info in user.contact_infos.filter(
                                        (el) => el.type === 'Work Phone Number'
                                      )"
                                      :key="contact_info.id"
                                    >
                                      {{ contact_info.number }}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Send Emails on Opportunities</td>
                                  <td>
                                    {{ user.send_summary_offer_email | yes_no }}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Blocked Status</td>
                                  <td>{{ user.blocked | yes_no }}</td>
                                </tr>
                              </tbody>
                            </v-simple-table>

                            <br />
                          </div>

                          <v-btn
                            @click="
                              $router.push({
                                path: '/dashboard/admin/user/add',
                              })
                            "
                          >
                            Add New User
                          </v-btn>

                          &nbsp;

                          <v-btn
                            @click="
                              $router.push({
                                path: `/dashboard/admin/company/associate_user/`,
                                query: {
                                  company_id: `${company_id}`,
                                  view_type: 'authorized',
                                },
                              })
                            "
                          >
                            Add Existing User
                          </v-btn>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel ref="company_note" class="bordered">
                  <v-expansion-panel-header>
                    <h3 class="d-flex align-center">
                      Notes
                      <v-icon class="ml-2"> mdi-note-edit-outline </v-icon>
                    </h3>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <!-- note list -->
                    <v-card
                      style="background: transparent"
                      ref="note_list"
                      flat
                    >
                      <p class="mb-0 grey--text font-weight-medium">
                        Click on the text field to input your notes, you can
                        also add screenshots or image files by copy/paste or
                        drag and drop into the text field below.
                      </p>

                      <v-card-text>
                        <p
                          v-if="companyData.notes.length === 0"
                          class="text-center grey--text text-subtitle-1"
                        >
                          No notes available. Make a note below.
                        </p>

                        <div
                          v-for="(note, index) in companyData.notes"
                          :key="note.id"
                          class="my-2"
                        >
                          <v-card class="rounded-lg">
                            <v-card-text>
                              <p class="d-flex align-center mb-0">
                                <span>
                                  <v-icon class="text-body-1 mr-1">
                                    mdi-account-outline
                                  </v-icon>
                                  {{ note.user ? note.user.first_name : "" }}
                                  {{ note.user ? note.user.last_name : "" }}
                                </span>

                                <span class="ml-auto">
                                  <v-icon class="text-body-1 mr-1">
                                    mdi-calendar-outline
                                  </v-icon>
                                  {{ note.createdAt | formatDate }}
                                </span>

                                <span class="ml-5">
                                  <v-btn
                                    title="Click to edit note"
                                    @click="
                                      handle_edit_company_note(
                                        note,
                                        index,
                                        $event
                                      )
                                    "
                                    icon
                                    small
                                  >
                                    <v-icon color="teal" class="text-body-1">
                                      mdi-pencil-outline
                                    </v-icon>
                                  </v-btn>

                                  <v-btn
                                    title="Click to delete"
                                    @click="handle_delete_company_note(note.id)"
                                    icon
                                    small
                                  >
                                    <v-icon color="red"> mdi-close </v-icon>
                                  </v-btn>
                                </span>
                              </p>
                            </v-card-text>

                            <v-divider />

                            <v-card-text>
                              <div v-if="note.note" style="white-space: pre">
                                {{ note.note }}
                              </div>

                              <div
                                v-if="note.content"
                                class="note-contents"
                                v-html="note.content"
                              ></div>
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-card-text>
                    </v-card>

                    <!-- note editor -->
                    <v-card
                      style="background: transparent"
                      ref="note_editor"
                      flat
                    >
                      <v-card-text class="pa-1">
                        <div>
                          <RichTextEditor
                            ref="tipTapEditor"
                            :id="company_id"
                            type="company"
                            :user_id="$store.getters['Auth/getAuthUser'].id"
                            @handleSaveNote="handle_save_company_note"
                          />
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-dialog v-model="note_dialog" fullscreen>
                <v-toolbar class="sticky-toolbar orange darken-3" flat>
                  <v-toolbar-title>
                    <v-icon>mdi-image</v-icon> Image Preview
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="note_dialog = false" title="Close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card>
                  <v-card-text>
                    <v-img :src="selectedImage" aspect-ratio="1" />
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import filters from "@/plugins/filters";
import RichTextEditor from "@/components/note/TipTapEditor.vue";

export default {
  name: "company_read",
  props: ["company_id"],

  components: { RichTextEditor },

  data() {
    return {
      panels: [0, 1, 2, 3, 4],
      loadingNotes: false,
      addNoteActive: false,
      componentLoading: true,
      submitError: "",
      archived_accounts: [],

      formRules: {
        valid: false,
        required: [(value) => !!value || "This is a required field."],
      },

      temp_company_note_index: null,
      note_dialog: false,
      selectedImage: "",

      form_response_headers: [
        { text: "Form ID", value: "id" },
        { text: "FAO", value: "account.name" },
        { text: "Title", value: "title" },
        { text: "Description", value: "description" },
        { text: "Status", value: "status" },
        { text: "Created", value: "createdAt" },
      ],

      account_headers: [
        { text: "Name", value: "name" },
        { text: "Category", value: "category" },
        { text: "Type", value: "type" },
        { text: "Account Number", value: "account_number" },
        { text: "Renewal Responsibility", value: "renewal_responsibility" },
      ],

      companyData: {},
      lenderContacts: {},
      lenderAddresses: {},
      lenderNotes: {},
      lenderAccounts: {},
    };
  },

  computed: {
    present_address() {
      return this.companyData.addresses.find((address) => address.present);
    },
  },

  methods: {
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    },

    scrollToElement(element) {
      window.scrollTo({
        top: this.getOffsetTop(element) - 100, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    handle_save_company_note(note, it_is_edit) {
      if (it_is_edit) {
        this.companyData.notes[this.temp_company_note_index].content =
          note.content;
        this.temp_company_note_index = null;

        const note_list = this.$refs.note_list.$el;
        this.scrollToElement(note_list);
      } else {
        this.companyData.notes.push(note);
      }
    },

    async handle_delete_company_note(id) {
      try {
        if (window.confirm("Are you sure you want to delete this note?")) {
          this.loadingNotes = true;
          const response = await API().post(
            `/api/internal-admin/note/delete?note_id=${id}`
          );

          console.log("Response: ", response.message);

          this.companyData.notes = this.companyData.notes.filter(
            (note) => note.id !== id
          );
        } else {
          console.log("Delete cancelled");
          return;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    handle_edit_company_note(note, index, event) {
      event.preventDefault();

      this.temp_company_note_index = index;

      // set the editor content
      this.$refs.tipTapEditor.setContentToEdit(note);

      const note_editor = this.$refs.note_editor.$el;
      this.scrollToElement(note_editor);
    },

    onCompanyNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
        console.log("clicked");
      }
    },

    // async refreshNotes() {
    //   console.log("refresh notes firing read");
    //   await API()
    //     .get(`/lenderNotes/${this.lenderId}`)
    //     .then((res) => {
    //       this.lenderNotes = res.data;
    //       this.loadingNotes = false;
    //       this.closeNote();
    //     })
    //     .catch((e) => {
    //       console.log("the catch block in refreshnotes is firing");
    //       console.log(e);
    //     });
    // },

    // addNote() {
    //   this.addNoteActive = true;
    // },

    // closeNote() {
    //   this.addNoteActive = false;
    // },

    handle_create_investor_kyc() {
      this.$router.push({
        path: `/dashboard/admin/company/${this.company_id}/investor_kyc`,
      });
    },

    async send_investor_kyc_link() {
      try {
        const res = await API().post("api/form/create_form_access_link", {
          company_id: this.company_id,
          form_unique_id: "investor-kyc-2023-v1",
        });

        if (res.status === 201) {
          console.log(res.data);
        } else {
          console.log("form access link not created.");
        }
      } catch (error) {
        console.log(error);
      }
    },

    view_form_response(value) {
      this.$router.push({
        path: `/dashboard/admin/form_response/${value.frm.unique_id}/read/${value.id}`,
      });
    },

    handle_update_account(value) {
      this.$router.push({
        path: `/dashboard/admin/company/account/update/${value.id}`,
      });
    },

    async handle_unarchive_account(value) {
      try {
        const confirm = window.confirm(
          `Are you sure you want to unarchive this account?`
        );

        if (confirm) {
          const res = await API().post(`api/account/unarchive_account`, {
            id: value.id,
          });

          if (res.status === 201) {
            console.log("account was unarchived");

            // remove the unarchived account from the archived_accounts array
            this.archived_accounts = this.archived_accounts.filter(
              (account) => account.id !== value.id
            );

            // push the unarchived account to the accounts array
            this.companyData.accounts.push(value);
          }
        }

        if (!confirm) {
          console.log("account was not unarchived");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    try {
      const companyData = await API().get(
        `/api/internal-admin/company/${this.company_id}`
      );

      if (companyData.status == 200) {
        // console.log(companyData);
        this.companyData = companyData.data;
      }

      const archived_accounts = await API().get(
        `/api/account/read_archived_accounts?company_id=${this.company_id}`
      );
      this.archived_accounts = archived_accounts.data;
    } catch (error) {
      console.error(error);
    }

    this.componentLoading = false;

    this.$nextTick(() => {
      if (this.$refs.note_list) {
        this.$refs.note_list.$el.addEventListener(
          "click",
          this.onCompanyNoteImageClick
        );
      }
    });
  },

  beforeDestroy() {
    this.$refs.note_list.$el.removeEventListener(
      "click",
      this.onDealNoteImageClick
    );
  },

  filters: {
    ...filters,
  },
};
</script>

<style lane="scss">
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.side-nav {
  background-color: #111 !important;
  color: white !important;
  top: 100px !important;
  position: sticky !important;
  position: -webkit-sticky !important;
  min-width: 100px;
}

.note-contents {
  p {
    img {
      max-width: 100%;  
      height: auto;
      cursor: pointer;
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #444;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #444;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
